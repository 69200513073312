import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import { DataContainsNode, useTransformNode } from '../../hooks/useTransformer';
import MDXLink from '../MDXLink';
import { MainTitle, TitleWrapper } from './CommonElement';
import SharedCss from './SharedCss';

const WhatsNewWrapper = styled.div`
  /** Common Css **/
  ${SharedCss}

  padding: 50px 30px;
  font-weight: 300;
  line-height: 1.6;
  background: #f5f5f5;

  /* PC */
  @media (min-width: 1024px) {
    padding: 120px 0 80px;
    p {
      font-size: 16px;
      line-height: 2;
    }
  }
`;

const WhatsNewsTitle = styled(TitleWrapper)`
  body & {
    /* PC */
    @media (min-width: 1024px) {
      margin-bottom: 40px;
    }
  }
`;

const WhatsNewsList = styled.ul`
  body & {
    padding: 20px;
    margin-bottom: 10px;
    background: #fff;

    /* TAB */
    @media (min-width: 768px) {
      padding: 10px 20px 0;
      margin-bottom: 0;
    }

    /* PC */
    @media (min-width: 1024px) {
      max-width: 1024px;
      margin: 0 auto !important;
    }
  }
`;

const WhatsNewListItem = styled.li`
  body & {
    padding: 0 0 20px;
    border-bottom: 1px solid #0093bb;

    &:last-of-type {
      border-bottom: 0;
    }

    /* TAB */
    @media (min-width: 768px) {
      display: flex;
      justify-content: space-between;
      padding-top: 10px;
      padding-bottom: 0;
    }

    /* PC */
    @media (min-width: 1024px) {
      padding: 20px;
    }
  }
`;

const WhatsNewListItemDate = styled.p`
  body & {
    margin-bottom: 0;
    color: #0093bb;

    /* TAB */
    @media (min-width: 768px) {
      width: 10%;
      margin: 0;
    }
  }
`;

const WhatsNewListItemTitle = styled.p`
  body & {
    margin-top: 0;

    /* TAB */
    @media (min-width: 768px) {
      width: 70%;
    }

    /* PC */
    @media (min-width: 1024px) {
      width: 75%;
      margin-bottom: 0;
    }
  }
`;

const WhatsNewListItemLink = styled.div`
  body & {
    text-align: right;

    /* TAB */
    @media (min-width: 768px) {
      width: 10%;
      margin-left: auto;
    }
  }
`;

const WhatsNewListItemLinkTag = styled(MDXLink)`
  body & {
    display: inline-block;
    padding-left: 15px;
    margin-left: auto;
    background: url(/img/projects/ico-arrow-blue.svg) no-repeat center left;
    background-size: 10px;
  }
`;

// const WhatsNewLink = styled.div`
//   body & {
//     padding-right: 15px;
//     text-align: right;
//
//     /* TAB */
//     @media (min-width: 768px) {
//       margin-top: 10px;
//     }
//
//     /* PC */
//     @media (min-width: 1024px) {
//       width: 1024px;
//       margin: 15px auto 0;
//     }
//   }
// `;
//
// const WhatsNewLinkTag = styled(MDXLink)`
//   body & {
//     display: inline-block;
//     padding-left: 15px;
//     color: #000 !important;
//     text-align: right;
//     text-decoration: none;
//     background: url(/img/projects/ico-arrow-gray.svg) no-repeat center left;
//     background-size: 10px;
//   }
// `;

const NEWS_DATA_QUERY = graphql`
  query NewsInfoDataAndNewsItemsData {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "projects-news" } } }
    ) {
      edges {
        node {
          id
          fields {
            language
            country
          }
          frontmatter {
            templateKey
            allNewsText
            allNewsUrl
            mainTitle
            newsItems {
              date
              title
              link
            }
          }
        }
      }
    }
  }
`;

function NewsItem(item: GatsbyTypes.MarkdownRemarkFrontmatterNewsItems) {
  return (
    <>
      <WhatsNewListItem>
        <WhatsNewListItemDate>{item.date}</WhatsNewListItemDate>
        <WhatsNewListItemTitle>{item.title}</WhatsNewListItemTitle>
        <WhatsNewListItemLink>
          {item.link && (
            <WhatsNewListItemLinkTag href={item.link}>
              MORE
            </WhatsNewListItemLinkTag>
          )}
        </WhatsNewListItemLink>
      </WhatsNewListItem>
    </>
  );
}

const News: React.FC = () => {
  const newsData =
    useStaticQuery<GatsbyTypes.NewsInfoDataAndNewsItemsDataQuery>(
      NEWS_DATA_QUERY,
    ) as DataContainsNode;
  const { frontmatter } = useTransformNode(newsData);
  return (
    <WhatsNewWrapper>
      <WhatsNewsTitle>
        <MainTitle>{frontmatter?.mainTitle}</MainTitle>
      </WhatsNewsTitle>
      <WhatsNewsList>
        {frontmatter?.newsItems?.map((item, index) => (
          <NewsItem
            key={'news-' + index}
            date={item?.date}
            title={item?.title}
            link={item?.link}
          />
        ))}
      </WhatsNewsList>
      {/*<WhatsNewLink>*/}
      {/*  <WhatsNewLinkTag href={frontmatter?.allNewsUrl}>*/}
      {/*    {frontmatter?.allNewsText}*/}
      {/*  </WhatsNewLinkTag>*/}
      {/*</WhatsNewLink>*/}
    </WhatsNewWrapper>
  );
};

export default News;
